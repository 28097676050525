<template>
  <c-box width="100%">
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        {
          label: `Manajemen Panduan Diet`,
          href: { name: 'admin.coaching-challenge' }
        },
        {
          label: `${_getContentType}`,
          href: { name: 'admin.coaching-challenge', query: { type: item.type } },
        },
        ...(['materi_harian', 'target_harian'].includes(item.type)
          ? [{
            label: `Bulan ${item.monthGroup}`,
            href: { name: 'admin.coaching-challenge', query: { type: item.type, month: item.monthGroup } },
          }]
          : []
        ),
        {
          label: `Detail ${_getContentType}`,
          isCurrent: true,
        },
      ]"
    />

    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding-block="['16px', '16px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <c-flex
        width="100%"
        justify-content="flex-start"
        margin-bottom="16px"
        padding-left="8px"
      >
        <c-button
          variant="ghost"
          py="0"
          px="8px"
          display="flex"
          align-items="center"
          gap="1rem"
          @click="onBack"
        >
          <c-image
            w="24px"
            h="24px"
            object-fit="cover"
            :src="require('@/assets/icon-chevron-left.svg')"
          />
          <c-text
            font-size="16px"
            color="primary.400" 
            font-weight="500"
          >
            Kembali
          </c-text>
        </c-button>
      </c-flex>
      <c-flex
        width="100%"
        justify="space-between"
        mb="30px"
        :padding-inline="['16px', '30px']"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
        >
          Detail Konten
        </BaseText>
        
        <c-flex gap="6px">
          <BaseButton
            color="danger"
            border-radius="1000px"
            :left-svg-icon="require('@/assets/icons/icon-trash.svg')"
            left-svg-icon-color="white"
            @click="isOpenModalConfirmDelete = true"
          >
            Hapus
          </BaseButton>

          <c-box
            v-if="item.type === 'tips_gizi'"
            position="relative"
          >
            <BaseButton
              color="warning"
              border-radius="1000px"
              :left-svg-icon="item.isHighlight === 1 ? require('@/assets/icons/icon-star.svg') : ''"
              left-svg-icon-color="white"
              :is-loading="isLoadingChangeHighlight"
              :disabled="item.isHighlight === 1 ? false : item.canHighlight !== 1"
              @click="toggleHighlight"
            >
              {{ item.isHighlight === 1 ? 'Hapus Highlight' : 'Jadikan Highlight' }}
            </BaseButton>
            <c-box
              v-if="item.canHighlight !== 1 && item.isHighlight !== 1"
              position="absolute"
              text-align="center"
              margin-top="8px"
              left="50%"
              transform="translateX(-50%)"
            >
              <BaseText
                size-mobile="10px"
                size-desktop="12px"
                white-space="nowrap"
              >
                Highlight sudah memenuhi kuota.
              </BaseText>
              <BaseText
                size-mobile="10px"
                size-desktop="12px"
                white-space="nowrap"
              >
                Konten tidak dapat dijadikan highlight
              </BaseText>
            </c-box>
          </c-box>

          <BaseButton
            color="primary"
            border-radius="1000px"
            :left-svg-icon="require('@/assets/icons/icon-edit-2.svg')"
            left-svg-icon-color="white"
            as="router-link"
            :to="{
              name: 'admin.coaching-challenge.edit',
              params: { id: id },
            }"
          >
            Edit
          </BaseButton>
        </c-flex>
      </c-flex>

      <c-box 
        width="100%"
        :padding-inline="['16px', '30px']"
        margin-top="40px"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
          text-align="center"
        >
          {{ item.title || '-' }}
        </BaseText>

        <c-box margin-top="16px">
          <BaseText
            v-for="categoryName in _getCategoryName"
            :key="categoryName"
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
            text-align="center"
          >
            {{ categoryName || '-' }}
          </BaseText>
        </c-box>

        <BaseText
          size-mobile="12px"
          size-desktop="14px-2"
          text-align="center"
        >
          {{ item.creator || '-' }}
        </BaseText>

        <BaseText
          v-if="['materi_harian', 'target_harian'].includes(item.type)"
          size-mobile="12px"
          size-desktop="14px-2"
          text-align="center"
          color="gray"
        >
          Konten Bulan {{ item.monthGroup || '-' }} Hari ke-{{ item.showOnDay || '-' }}
        </BaseText>

        <!--        <c-form-control-->
        <!--          v-if="!!item.coverImage"-->
        <!--          margin-top="16px"-->
        <!--          display="flex"-->
        <!--          justify-content="center"-->
        <!--          align-items="center"-->
        <!--        >-->
        <!--          <c-box-->
        <!--            max-width="640px"-->
        <!--            width="100%"-->
        <!--          >-->
        <!--            <c-image-->
        <!--              :src="getPhotoProgram(item.coverImage)"-->
        <!--              alt="file program"-->
        <!--              mx="auto"-->
        <!--              border-radius="12px"-->
        <!--            />-->
        <!--          </c-box>-->
        <!--        </c-form-control>-->

        <c-box
          v-for="(section, sectionIdx) in item.contentSection"
          :key="section.id"
          margin-top="16px"
        >
          <!-- SECTION YOUTUBE -->
          <c-form-control
            v-if="section.contentSectionType.sectionName === 'YouTube'"
            :margin-top="sectionIdx === 0 ? '' : '30px'"
          >
            <c-flex
              justify-content="center"
              align-items="center"
            >
              <c-box
                border-radius="12px"
                overflow="hidden"
              >
                <youtube
                  player-width="640"
                  :video-id="youtubeParser(section.url)"
                />
              </c-box>
            </c-flex>
            <BaseText
              margin-top="6px"
              color="gray"
              size-mobile="12px"
              size-desktop="14px-2"
              text-align="center"
            >
              {{ section.description }}
            </BaseText>
          </c-form-control>

          <!-- SECTION DESCRIPTION -->
          <c-form-control
            v-else-if="section.contentSectionType.sectionName === 'Description'"
            :margin-top="sectionIdx === 0 ? '' : '30px'"
          >
            <c-text
              color="superDarkGray.900"
              font-size="18px"
              line-height="27px"
              word-break="break-word"
              class="ck"
              v-html="section.description"
            />
          </c-form-control>

          <!-- SECTION IMAGE -->
          <c-form-control
            v-if="section.contentSectionType.sectionName === 'Gambar'"
            :margin-top="sectionIdx === 0 ? '' : '30px'"
          >
            <c-flex
              direction="column"
              justify-content="center"
              align-items="center"
            >
              <c-box
                width="100%"
                max-width="640px"
              >
                <c-image
                  :src="getPhotoProgram(section.url)"
                  alt="file program"
                  mx="auto"
                  border-radius="6px"
                />
              </c-box>
              <BaseText
                color="gray"
                size-mobile="12px"
                size-desktop="14px-2"
                text-align="center"
                margin-top="6px"
              >
                {{ section.description }}
              </BaseText>
            </c-flex>
          </c-form-control>

          <!-- SECTION UPLOAD_FILE -->
          <c-form-control
            v-if="section.contentSectionType.sectionName === 'Document/File'"
            :margin-top="sectionIdx === 0 ? '' : '30px'"
          >
            <c-flex align-items="center">
              <c-flex
                align-items="center"
                justify-content="center"
                h="100px"
                w="100px"
                mr="20px"
                bg="#F4CC4619"
                border-radius="12px"
              >
                <c-image
                  w="50px"
                  h="50px"
                  :src="require('@/assets/icon-folder.svg')"
                  alt="icon-folder"
                />
              </c-flex>
              <c-flex
                justify-content="center"
                align-items="baseline"
                flex-dir="column"
                flex="1"
              >
                <BaseText
                  size-mobile="14px-2"
                  size-desktop="16px"
                >
                  {{ section.description ? JSON.parse(section.description).fileName : '-' }}
                </BaseText>
                <BaseText
                  color="gray"
                  size-mobile="14px-2"
                  size-desktop="16px"
                >
                  {{ section.description ? formatBytes(JSON.parse(section.description).fileSize): '-' }}
                </BaseText>
              </c-flex>
              <c-flex
                align-items="center"
                justify-content="center"
                margin-left="auto"
                border-radius="12px"
                width="55px"
                height="55px"
                :_hover="{
                  backgroundColor: isLoadingDownloadFile[section.contentSectionTypeId] ? '' : '#e3f0ef',
                }"
                @click="download(section)"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-download.svg')"
                  :fill="isLoadingDownloadFile[section.contentSectionTypeId] ? '#888' : '#008C81'"
                />
              </c-flex>
            </c-flex>
          </c-form-control>

          <!-- SECTION CAROUSEL -->
          <c-box
            v-if="section.contentSectionType.sectionName === 'Carousel'"
            width="100%"
            max-width="800px"
            position="relative"
            :margin-top="sectionIdx === 0 ? '' : '30px'"
          >
            <VueSlickCarousel
              v-chakra="{
                '.slick-prev': {
                  width: '50px',
                  height: '50px',
                  left: '0',
                  backgroundImage: require('@/assets/icon_next_left.svg'),
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100%',
                  zIndex: '1',
                  '::before': {
                    display: 'none',
                  },
                  ':focus': {
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    width: '50px',
                    height: '50px',
                    backgroundImage: require('@/assets/icon_next_left.svg'),
                  },
                  ':hover': {
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    width: '50px',
                    height: '50px',
                    backgroundImage: require('@/assets/icon_next_left.svg'),
                  },
                  '@media(max-width: 767px)': {
                    display: 'none !important',
                  },
                },
                '.slick-next': {
                  right: '0',
                  width: '50px',
                  height: '50px',
                  backgroundImage: require('@/assets/icon_next_right.svg'),
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100%',
                  zIndex: '1',
                  '::before': {
                    display: 'none',
                  },
                  ':focus': {
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    width: '50px',
                    height: '50px',
                    backgroundImage: require('@/assets/icon_next_right.svg'),
                  },
                  ':hover': {
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    width: '50px',
                    height: '50px',
                    backgroundImage: require('@/assets/icon_next_right.svg'),
                  },
                  '@media(max-width: 767px)': {
                    display: 'none !important',
                  },
                },
                '.slick-dots': {
                  bottom: '8px',
                  '@media(max-width: 767px)': {
                    bottom: '15px',
                  },
                  li: {
                    '@media(max-width: 767px)': {
                      margin: '0 3px',
                    },
                    button: {
                      '::before': {
                        fontSize: '12px',
                        color: '#C4C4C4',
                        opacity: '1',
                        '@media(max-width: 767px)': {
                          fontSize: '10px',
                        },
                      },
                    },
                    '&.slick-active': {
                      button: {
                        '::before': {
                          color: '#008C81',
                          opacity: '1',
                        },
                      },
                    },
                  },
                },
                '.slick-slide': {
                  padding: '0 80px',
                  transform: 'scale(1)',
                  transitionProperty: 'all',
                  transitionDuration: '300ms',
                  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                  '@media(max-width: 767px)': {
                    padding: '0px',
                    transform: 'none',
                  },
                },
              }"
              v-bind="{
                arrows: true,
                dots: true,
                dotsClass: 'slick-dots custom-dot-class',
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
              }"
            >
              <c-box
                v-for="(photo, index) in section.contentCarouselImage"
                :key="'banner-' + index"
                as="a"
                target="_blank"
                pos="relative"
                class="item"
                width="100%"
                mx="auto"
                :mt="['0px', '30px']"
                :mb="['0px', '70px']"
              >
                <c-image
                  :src="photo.url"
                  :srcset="photo.url"
                  alt="dietela"
                  width="100%"
                  :box-shadow="['none', '0px 4px 8px rgba(0, 0, 0, 0.2)']"
                />
              </c-box>
            </VueSlickCarousel>
            <BaseText
              color="gray"
              size-mobile="12px"
              size-desktop="14px-2"
              position="absolute"
              text-align="center"
              bottom="40px"
              left="50%"
              transform="translateX(-50%)"
            >
              {{ section.description }}
            </BaseText>
          </c-box>
        </c-box>
      </c-box>
    </c-box>

    <!-- MODAL -->
    <BaseModal
      :is-open="isOpenModalConfirmDelete"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            src="https://ik.imagekit.io/dietela/pwa_webp/popup/delete.png?updatedAt=1690890254641"
            alt="image confirmation delete coupon"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            Anda yakin ingin hapus Konten <br> “{{ item.title }}”?
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoadingDelete"
            @click="isOpenModalConfirmDelete = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="danger"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingDelete"
            @click="onDelete"
          >
            Hapus
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="isOpenModalSuccessDelete"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            src="https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620"
            alt="image success delete coupon"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            Data berhasil dihapus!
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="handleBackReplace"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import { CBox, CFlex, CImage, CFormControl, CText } from '@chakra-ui/vue'
import { mapGetters, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { reqChangeHighlightContent } from '@/requests/dietela-api/admin/req-change-highlight-content'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import BaseModal from '@/components/elements/base-modal.vue'
import { youtubeParser } from '@/utils/youtubeParser'

export default {
  name: 'AdminCoachingChallengeDetail',
  components: {
    BaseModal,
    BaseButton,
    BaseText,
    CBox,
    CFlex,
    CImage,
    CText,
    CFormControl,
    VueSlickCarousel,
    BreadcrumbPath,
  },
  mixins: [generalMixin],
  data() {
    return {
      isLoadingDownloadFile: {},
      isLoadingChangeHighlight: false,
      isOpenModalConfirmDelete: false,
      isLoadingDelete: false,
      isOpenModalSuccessDelete: false,
    }
  },
  computed: {
    ...mapGetters({
      axios: 'axios',
      item: 'admContentChallenge/item',
    }),
    _getCategoryName() {
      return this.item?.categoryName?.map((category) => {
        const subCategoryString = category?.subCategories?.reduce((a, b, index) => {
          if (index === 0) return a + b.subCategoryName
          return `${a}, ${b.subCategoryName}`
        }, '')
        return `${category?.categoryName}${subCategoryString ? `: ${subCategoryString}` : ''}`
      })
    },
    _getContentType() {
      switch (this.item.type) {
        case 'tips_gizi':
          return 'Tips Gizi'
        case 'materi_harian':
          return 'Materi Harian'
        case 'target_harian':
          return 'Target Harian'
        default:
          return ''
      }
    },
    id() {
      return this.$route.params.id
    },
  },
  async mounted() {
    this.detailContentChallenge(this.id)
  },
  methods: {
    ...mapActions({
      detailContentChallenge: 'admContentChallenge/detailContentChallengeAdmin',
      deleteContentChallenge: 'admContentChallenge/deleteContentChallengeAdmin',
    }),
    youtubeParser,
    download(section) {
      if (this.isLoadingDownloadFile[section.contentSectionTypeId]) return
      this.isLoadingDownloadFile[section.contentSectionTypeId] = true
      fetch(section.url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = JSON.parse(section.description).fileName
          link.click()
        })
        .catch(() => {
          this.$toast({
            title: 'Failed',
            description: 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }).
        finally(() => {
          this.isLoadingDownloadFile[section.contentSectionTypeId] = false
        })
    },
    async toggleHighlight() {
      this.isLoadingChangeHighlight = true
      try {
        await reqChangeHighlightContent(
          this.$store.getters.axios,
          { id: this.id },
          { isHighlight: this.item.isHighlight === 1 ? 0 : 1 },
        )
        this.item.isHighlight = this.item.isHighlight === 1 ? 0 : 1
        this.item.canHighlight = this.item.canHighlight === 1 ? 0 : 1
      } catch (e) {
        this.$toast({
          title: 'Failed',
          description: parseErrorCatch(e),
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
      } finally {
        this.isLoadingChangeHighlight = false
      }
    },
    handleBackReplace() {
      this.$router.push({
        name: 'admin.coaching-challenge',
      })
    },
    onDelete(state) {
      this.isOpen = state
      this.isLoadingDelete = true
      if (!this.isOpen) return
      this.deleteContentChallenge(this.id)
        .then(() => {
          this.isOpenModalSuccessDelete = true
        })
        .catch((e) => {
          this.$toast({
            title: 'Failed',
            description: parseErrorCatch(e),
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }).finally(() => {
          this.isLoadingDelete = false
        })
    },
    onBack() {
      this.$router.push(`/admin/coaching-challenge?type=${this.item?.type}${['target_harian', 'materi_harian'].includes(this.item?.type) ? ('&month=' + this.item?.monthGroup) : ''}`)
    },
  },
}
</script>
<style>
table {
  width: 100%;
  border: thin solid #c4c4c4;
}
table tr th {
  border-left: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  padding: 4px 8px;
}
table tr td {
  border-left: thin solid #c4c4c4;
  border-bottom: thin solid #c4c4c4;
  padding: 4px 8px;
}
</style>